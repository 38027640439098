import { useEffect, useState } from "react";
import { useParams } from "react-router";
import FetchPostsInTopic from "../../../Services/Forum/FetchPostsInTopic";
import PostListItem from "../PostListItem/PostListItem";
import FetchTopicDetail from "../../../Services/Forum/FetchTopicDetail";
import { Link } from "react-router-dom";
import { FaNewspaper } from "react-icons/fa";
import Pagination from "../../Common/Pagination";

const PostList = () => {
  const { temaId } = useParams();
  const [isLoading, setIsLoading] = useState();
  const [posts, setPosts] = useState([]);
  const [topic, setTopic] = useState({});

  const [url, setUrl] = useState("");
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const [articlesCount, setArticlesCount] = useState(0);

  if (url === "") {
    setUrl(
      `${process.env.REACT_APP_API_URL}foro/temas/${temaId}/publicaciones`
    );
  }

  const handleNext = () => {
    if (nextUrl) {
      setUrl(nextUrl);
      document
        .getElementById("article-list")
        .scrollIntoView({ behavior: "smooth" });
    } else {
      return () => {};
    }
  };

  const handlePrev = () => {
    if (prevUrl) {
      setUrl(prevUrl);
      document
        .getElementById("article-list")
        .scrollIntoView({ behavior: "smooth" });
    } else {
      return () => {};
    }
  };

  useEffect(() => {
    if (temaId) {
      setIsLoading(true);
      let isSubscribed = true;
      FetchTopicDetail(temaId).then((resp) => {
        if (isSubscribed) {
          setTopic(resp);
        }
      });
      FetchPostsInTopic(url).then((resp) => {
        if (isSubscribed) {
          setPosts(resp.results);
          setNextUrl(resp?.next);
          setPrevUrl(resp?.previous);
          setArticlesCount(resp?.count);
          setIsLoading(false);
        }
      });
      return () => (isSubscribed = false);
    }
  }, [temaId, url]);

  return (
    <div className="w-full overflow-hidden sm:w-3/4 bg-white">
      <div className="flex">
        <div className="bg-white w-3/4 font-Midnight text-red-congreso200 p-3">
          <Link to={{ pathname: "/foro" }} className="text-xl">
            Temas
          </Link>{" "}
          / <span className="text-2xl">{topic.nombre}</span>
        </div>
        <div className="w-1/4">
          <Link to={{ pathname: `/nuevo_hilo/${topic.id}` }}>
            <button
              type="button"
              className="bg-blue-600 text-white p-2 mt-2 rounded  leading-none flex items-center"
            >
              Nuevo Hilo{" "}
              <span className="bg-white p-1 rounded text-blue-600 text-xs ml-2">
                <FaNewspaper />
              </span>
            </button>
          </Link>
        </div>
      </div>
      <ul id="article-list" className="divide-y divide-gray-100">
        {posts.map((item, i) => (
          <PostListItem key={i} item={item} />
        ))}
      </ul>
      {articlesCount > process.env.REACT_APP_ARTICLES_PER_PAGE && (
        <Pagination
          handleNext={nextUrl ? handleNext : null}
          handlePrev={prevUrl ? handlePrev : null}
          nextString="Hilos anteriores"
          prevString="Hilos siguientes"
        />
      )}
    </div>
  );
};

export default PostList;
