const FetchArticleDetail = (articleId, user = null) => {
  /*
        RETURN articleId DETAILS
    */
  let url = `${process.env.REACT_APP_API_URL}blog/articulos/${articleId}`;

  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  if (user !== null) {
    headers["Authorization"] = "Token " + user.token;
  }

  const response = fetch(url, {
    method: "get",
    headers,
  })
    .then((resp) => resp.json())
    .catch((error) => console.log(error));
  return response;
};

export default FetchArticleDetail;
